import React, { Component } from "react";
import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiLayers />,
    title: "Website Development",
    description:
      "We create stunning mobile & SEO optimized websites to make sure you business is at the forefont of your competitors.",
  },
  {
    icon: <FiMonitor />,
    title: "Mobile App Development",
    description:
      "Apps are powerful, we can make sure your business is in your customers pocket with a stunning iOS / Android App",
  },
  {
    icon: <FiUsers />,
    title: "Efficiency Software",
    description:
      "Reduce staffing costs by automating even the smallest tasks in your business and save £1000's a year.",
  },
  ,
  {
    icon: <FiUsers />,
    title: "Enterprise Software",
    description:
      "Have a large bespoke project? We are experienced in large scale high volume software.",
  },
  {
    icon: <FiMonitor />,
    title: "Emergency Assistance",
    description:
      "Is your website broken? Expericnecing hsoting issues? We can help.",
  },
];

class ServiceThree extends Component {
  render() {
    const { column } = this.props;
    const ServiceContent = ServiceList.slice(0, this.props.item);

    return (
      <React.Fragment>
        <div className="row">
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              <a href="/service-details">
                <div className="service service__style--2">
                  <div className="icon">{val.icon}</div>
                  <div className="content">
                    <h3 className="title">{val.title}</h3>
                    <p>{val.description}</p>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceThree;
