import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaRef = React.createRef();

const Result = () => {
  return (
    <p className="success-message">
      Your Message has been successfully sent. We will contact you soon.
    </p>
  );
};

function ContactForm({ props }) {
  const [result, showresult] = useState(false);
  const form = useRef(null);

  function submit(e) {
    const data = new FormData(form.current);
    console.log(data);
    fetch("/api/contact.php", {
      method: "post",

      body: data,
    })
      .then(function (response) {
        showresult(true);
      })

      .catch(function (error) {
        alert("Unable to send, please try again.");
        console.log("Request failed", error);
      });
    console.log(e);
  }

  return (
    <form
      ref={form}
      action={(e) => {
        e.preventDefault();
        console.log(e);
        recaptchaRef.current.execute();
      }}
      onSubmit={(e) => {
        e.preventDefault();
        console.log(e);
        recaptchaRef.current.execute();
      }}
    >
      <div className="rn-form-group">
        <input type="text" name="fullname" placeholder="Your Name" required />
      </div>

      <div className="rn-form-group">
        <input type="email" name="email" placeholder="Your Email" required />
      </div>

      <div className="rn-form-group">
        <input type="text" name="phone" placeholder="Phone Number" required />
      </div>

      <div className="rn-form-group">
        <input type="text" name="subject" placeholder="Subject" required />
      </div>

      <div className="rn-form-group">
        <textarea name="message" placeholder="Your Message" required></textarea>
      </div>

      <div className="rn-form-group">
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey="6LcZdEwpAAAAALW1XPakl1du8ePAc7MzIlAu_7IJ"
          onChange={submit}
        />
        <button
          className="rn-button-style--2 btn-solid"
          type="submit"
          value="submit"
          name="submit"
          id="mc-embedded-subscribe"
        >
          Submit Now
        </button>
      </div>

      <div className="rn-form-group">{result ? <Result /> : null}</div>
    </form>
  );
}
export default ContactForm;
