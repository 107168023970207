import React, { Component } from "react";
import { Helmet } from "react-helmet";

class PageHelmet extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.props.pageTitle} || Saase Software </title>
          <meta
            name="description"
            content="Elevate your digital presence with our cutting-edge software solutions. As a leading software agency, we specialize in crafting bespoke web, mobile apps, and tailored software solutions to propel your business forward. Unlock innovation and efficiency with our expert team dedicated to transforming your ideas into seamless, powerful digital experiences. Explore the limitless possibilities of technology with our comprehensive services."
          />
        </Helmet>
      </React.Fragment>
    );
  }
}

export default PageHelmet;
